import React from "react"

const confirm = () => {
  return (
    <div>
      <h1>confirm email address complete</h1>
    </div>
  )
}

export default confirm
